import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

const videos = [
  {
    title: "零代码\n让交易不再复杂",
    subtitle: "享受多屏幕、多市场、多账号交易",
    image: require("../../images/home-video1.png") as string,
    videoUrl: require("./video1.mp4") as string,
  },
  {
    title: "DO 语言",
    subtitle: "兼容文华财经麦语言，策略逻辑简洁，非专业程序员也能快速开发策略",
    image: require("../../images/home-video2.png") as string,
    videoUrl: require("./video2.mp4") as string,
  },
  {
    title: "Python 策略",
    subtitle: "功能强大，生态丰富",
    image: require("../../images/home-video2.png") as string,
    videoUrl: require("./video3.mp4") as string,
  },
];

export function Section1() {
  const [active, setActive] = useState(0);
  return (
    <section id="section1" class="main-container py-8 md:py-20">
      {videos.map((item, index) => (
        <div
          class={classNames(
            "flex flex-col lg:flex-row",
            index != active && "hidden"
          )}
        >
          <div class="w-64 mr-8 mb-8 lg:mb-0">
            <pre class="text-3xl sm:text-4xl leading-tight text-left lg:text-right font-semibold border-b-[5px] pb-4 mb-4 border-[#2794fd]">
              {item.title}
            </pre>
            <p class="text-gray-700 text-left lg:text-right">{item.subtitle}</p>
          </div>
          <VideoPlayer {...item} />
        </div>
      ))}
      <div class="flex gap-4 mt-8 pl-0 lg:pl-72">
        {videos.map((_, index) => (
          <button
            class={classNames(
              "w-12 h-1.5 rounded-full",
              index == active ? "bg-[#2794fd]" : "bg-[#cfe8ff]"
            )}
            onClick={() => setActive(index)}
          />
        ))}
      </div>
    </section>
  );
}

function VideoPlayer(item: (typeof videos)[number]) {
  const [playing, setPlaying] = useState(false);
  const ref = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    setPlaying(!(ref.current?.paused ?? true));
  }, []);
  function toggle() {
    if (playing) {
      ref.current?.pause();
    } else {
      ref.current?.play();
      ref.current!.muted = false;
    }
  }
  return (
    <div class="relative flex-1 flex justify-center items-center p-6 bg-[#c9dffc] rounded-lg shadow-lg shadow-[#0c58bb1a]">
      {item.videoUrl ? (
        <video
          src={item.videoUrl}
          class="aspect-[1.78] object-cover w-full h-full"
          ref={ref}
          muted
          autoplay
          loop
          onClick={toggle}
          onPlaying={() => {
            setPlaying(true);
          }}
          onPause={() => {
            setPlaying(false);
          }}
        />
      ) : (
        <img
          src={item.image}
          class="aspect-[1.78] object-cover w-full h-full"
          loading="lazy"
        />
      )}
      {!playing && (
        <div
          class="absolute bg-blue-400 rounded-lg pl-1 w-24 h-20 flex items-center justify-center"
          onClick={toggle}
        >
          <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.3067 16.9272C40.2225 18.41 40.2225 22.5754 37.3067 24.0582L5.97312 39.9922C3.31202 41.3454 0.159986 39.4121 0.159986 36.4267L0.159988 4.55873C0.159988 1.57331 3.31202 -0.359983 5.97313 0.99326L37.3067 16.9272Z"
              fill="white"
            />
          </svg>
        </div>
      )}
    </div>
  );
}
