import classNames from "classnames";
import { useState } from "react";

export function Section2() {
  const items = [
    {
      title: "AI 智选",
      subtitle: "甄别当下最为热门的标的",
      image: require("../../images/home-section2-img1.png"),
      icon: require("../../images/home-section2-icon1.png"),
    },
    {
      title: "策略模板",
      subtitle: "降低学习门槛",
      image: require("../../images/home-section2-img2.png"),
      icon: require("../../images/home-section2-icon2.png"),
    },
    {
      title: "测试器",
      subtitle: "快速调试验证你的策略",
      image: require("../../images/home-section2-img3.png"),
      icon: require("../../images/home-section2-icon3.png"),
    },
    {
      title: "深度回测",
      subtitle: "历史评估，抗压检验，稳健策略",
      image: require("../../images/home-section2-img4.png"),
      icon: require("../../images/home-section2-icon4.png"),
    },
    {
      title: "盘面分析",
      subtitle: "随时随地，掌控盘面! 科学操盘，效率更高!",
      image: require("../../images/home-section2-img5.png"),
      icon: require("../../images/home-section2-icon5.png"),
    },
  ];
  const [active, setActive] = useState(0);
  return (
    <section id="section2" class="relative">
      <img
        src={require("../../images/home-section2-bg.png")}
        class="absolute object-cover w-full h-full opacity-50"
        loading="lazy"
      />
      <div class="py-8 md:py-16 relative px-4 md:px-8 xl:px-0">
        <h3 class="text-3xl sm:text-4xl font-semibold text-center mb-6 md:mb-12">
          聚集多项智能工具
        </h3>
        <div class="main-container p-4 md:py-8 flex rounded-xl shadow-lg bg-white">
          <div class="w-full xl:w-[400px] xl:mr-10">
            {items.map((item, index) => (
              <div
                class="flex-col cursor-pointer border-b border-gray-200 pb-5 mb-5 last:border-b-0 last:pb-0 last:mb-0"
                onMouseEnter={() => setActive(index)}
                onClick={() => setActive(index)}
              >
                <div class="flex items-center">
                  <div class="w-9 h-12 sm:w-12 sm:h-16a mr-3 sm:mr-4">
                    <img
                      src={item.icon}
                      class="object-cover w-full h-full"
                      loading="lazy"
                    />
                  </div>
                  <div
                    class={classNames(
                      "flex-1",
                      index == active && "text-[#086de7]"
                    )}
                  >
                    <h4 class="text-base sm:text-xl font-semibold mb-1 sm:mb-3">
                      {item.title}
                    </h4>
                    <p class="text-xs sm:text-sm opacity-80">{item.subtitle}</p>
                  </div>
                  <svg
                    width="9"
                    height="12"
                    viewBox="0 0 9 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.45665 6L0.933319 1.4L2.30999 0L8.20999 6L2.30999 12L0.933319 10.6L5.45665 6Z"
                      fill={index == active ? "#086de7" : "#666"}
                    />
                  </svg>
                </div>
                <div
                  class={classNames(
                    "xl:hidden mt-4",
                    index != active && "hidden"
                  )}
                >
                  <img
                    src={items[active].image}
                    class="mx-auto object-cover"
                    loading="lazy"
                  />
                </div>
              </div>
            ))}
          </div>
          <div class="flex-1 hidden xl:block">
            <img
              src={items[active].image}
              class="aspect-[1.54] object-cover"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
