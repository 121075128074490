import { links } from "../../page";

export function Header() {
  return (
    <section class="relative bg-(cover center gradient-to-r) from-[#ecf6ff] to-[#c5e1ff]">
      <img
        src={require("../../images/home-header-bg.png")}
        class="absolute object-cover w-full h-full opacity-50"
      />
      <div class="main-container h-[500px] sm:h-[560px] relative flex flex-col justify-evenly">
        <div class="z-10">
          <h1 class="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4 lg:mb-8">
            多元量化 APP
          </h1>
          <p class="text-base sm:text-xl transition-all">
            Python、零代码 AI 策略，期货、期权、证券交易终端
          </p>
        </div>
        <div class="flex flex-col items-center lg:items-start">
          <img
            src={require("../../images/home-header.png")}
            class="w-[512px] lg:absolute lg:w-1/2 bottom-32 right-0 drop-shadow-2xl"
          />
          <div class="flex z-10 mt-8 lg:mt-0">
            <a class="mr-4 btn-primary w-40 h-12" href={links.download}>
              免费下载
            </a>
            <a class="btn-secondary w-40 h-12" href={links.tutorial}>
              使用指导
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
