export function Section4() {
  const items = [
    {
      title: "多平台兼容，云平台管理",
      subtitle: "Windows、Mac OS、iOS、Android、鸿蒙。随时随地均可操控",
    },
    {
      title: "专业投研，收益保障",
      subtitle:
        "集策略研发、验证、交易管理于一体，严格检验、层层审核、高标输出",
    },
    {
      title: "操作简单，使用方便",
      subtitle: "专家级模板，一键启用零代码策略，快速创建",
    },
    {
      title: "专属定制，服务全面",
      subtitle: "定制专属策略；定制专业功能",
    },
  ];
  return (
    <section
      id="section4"
      class={`bg-[url(${require("../../images/home-section4-bg.png")})] bg-cover`}
    >
      <div class="main-container py-8 sm:py-20">
        <h3 class="text-3xl sm:text-4xl font-semibold text-center mb-16">
          专业开发 精准定制
        </h3>
        <div class="flex flex-col lg:flex-row">
          <img
            src={require("../../images/home-section4-img.png")}
            class="w-64 lg:w-[500px] mx-auto mb-8 sm:mb-16 lg:mx-0 drop-shadow-2xl"
            loading="lazy"
          />
          <div class="flex-1 flex flex-col gap-6 sm:gap-12">
            {items.map((item) => (
              <div>
                <p class="text-lg sm:text-2xl font-semibold mb-2">
                  {item.title}
                </p>
                <p class="text-sm sm:text-base text-gray-700">
                  {item.subtitle}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
