export function Section3() {
  const items = [
    {
      title: "机器训练",
      subtitle:
        "百万级因子加持，AI机器训练，使策略模型输出更具稳定性、高效性、精准性。",
    },
    {
      title: "AI 调仓",
      subtitle:
        "利用人工智能技术进行仓位管理，理性决策，优化投资组合，提高收益控制风险。",
    },
    {
      title: "算法交易",
      subtitle:
        "智能拆单，精准套利；高效、精准、客观、灵活、低成本、风险可控。",
    },
    {
      title: "全面报告",
      subtitle: "实时跟踪，自动汇整。AI自动化分析，提供专业报告。",
    },
  ];
  return (
    <section id="section3" class="main-container py-8 md:py-20">
      <h3 class="text-3xl sm:text-4xl font-semibold text-center mb-6 md:mb-16">
        AI 加持 投研必备
      </h3>
      <div class="flex-col-reverse flex lg:flex-row">
        <div class="flex-1 flex flex-col gap-6 lg:gap-12">
          {items.map((item) => (
            <div>
              <p class="text-lg md:text-2xl font-semibold mb-2">{item.title}</p>
              <p class="text-sm md:text-base text-gray-700">{item.subtitle}</p>
            </div>
          ))}
        </div>
        <img
          src={require("../../images/home-section3-img.png")}
          class="w-64 mb-8 md:mb-16 lg:mb-0 lg:w-[500px] mx-auto drop-shadow-2xl"
          loading="lazy"
        />
      </div>
    </section>
  );
}
